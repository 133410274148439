.boardMain {
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
  transition: background-image 0.4s ease-in-out;
}

.main {
  background: radial-gradient(67.04% 67.04% at 50.04% 28.65%, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0.2) 100%);
  margin: 0;
}

.mainNewBoard {
  background: none;
}

.grid {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.compact-grid {
  /********** Mimic tab behaviour to fix chrome flashing bug *****************/
  transform: translate(0, 0);
  will-change: transform;
  overflow: hidden;
}
.boardTitle {
  font-size: 1rem;
  color: #fff;
  position: absolute;
  top: 0;
  margin: 0;
  left: 48px;
  height: 48px;
  display: flex;
  align-items: center;
}

.boardTitle svg {
  margin: 0 0 0 4px;
}

.boardMenu .boardTitle {
  position: static;
  font-weight: 500;
  user-select: none;
  float: left;
  margin: 0 0 0 64px;
}
.boardTitle-facilitator {
  cursor: pointer;
}
.boardMenu .boardTitle-facilitator:after {
  content: '';
  width: 18px;
  height: 18px;
  margin: 0 0 0 22px;
}
.boardMenu .boardTitle-facilitator:hover:after {
  background: url(../images/icons/edit-white.svg);
}

.boardMain-authenticated-false .feedbackBox {
  margin-right: 68px;
}

.feedbackBox {
  margin-right: 54px;
}

.help {
  margin-right: 54px;
}
.help ul {
  text-align: left;
  font-weight: 500;
  padding: 12px 0 6px 24px;
}

.help li {
  list-style: outside;
  margin: 0 0 12px 0;
}

.actionPanel {
  float: right;
  cursor: pointer;
  margin: 0;
  padding: 0 16px;
  user-select: none;
}

.boardMenu-admin .actionPanel {
  float: left;
}
.boardTitlePanel .actionPanel {
  float: left;
  margin: 0 0 0 8px;
  padding: 0 14px;
}

.retroControlPanel-icon {
  margin: 0 12px 0 0;
}

.boardTitlePanel input,
.modalContainer input {
  padding: 10px 10px;
  border: 1px solid #a7a7a7;
  border-radius: 10px;
  color: #333;
  width: 172px;
  margin: 10px 4px 10px 10px;
  background: #eee;
  font-size: 15px;
}
.modalContainer .MuiSwitch-root input {
  margin: 0;
}
.modalContainer input {
  width: 80%;
  max-width: 350px;
}

#root .loginBtn,
.actionPanel {
  line-height: 48px;
  border-radius: 10px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
#root .loginBtn:hover,
.actionPanel:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.actionPanelList {
  z-index: 20;
  position: absolute;
  top: 0;
}

.profile .actionPanelList {
  top: 7px;
}

.actionPanelBoard {
  width: 16rem;
  padding: 12px;
  text-align: center;
}

.actionPanelBoard-profile,
.actionPanelBoard-orgMenu {
  width: 320px;
  margin: 9px 0 0 0;
  padding: 12px 0 6px 0;
}

.actionPanelBoard-shareLink {
  width: 400px;
}

.actionPanelBoard-actions {
  width: min(520px, calc(100vw - 18px));
}

.actionPanelBoard-users {
  width: 384px;
}

.actionPanelBoard-stats {
  width: 384px;
}

.actionPanelBoard-feedback {
  width: 384px;
}

.actionPanelBoard-stats--value {
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 12px 0 !important;
}

.actionPanelBoard-stats--value span {
  font-size: 30px;
  padding-right: 4px;
}

.actionPanelBoard-end {
  width: 280px;
}

.actionPanelBoard h3 {
  font-size: 1em;
  margin: 6px 0 0;
  font-weight: 600;
}

.actionPanelBoard .MuiFormControl-root {
  width: 90%;
  margin: 12px auto;
}
.actionPanelBoard-feedback .MuiFormControl-root {
  width: 100%;
  margin: 12px 4px;
}

.actionPanelBoard-feedback--resources {
  text-align: left;
  padding: 12px;
}

.actionPanelBoard-feedback--resources a {
  display: flex !important;
}

.actionPanelBoard-feedback--resources svg {
  width: 16px;
  margin-left: 4px;
}

.actionPanelBoard input {
  text-align: center;
}

.actionPanelBoard-actions input {
  text-align: left;
}

.actionPanelBoard .text-button {
  margin: 12px 0;
  display: block;
}

.actionPanel .MuiBadge-badge {
  right: -5px;
  top: 13px;
  user-select: none;
}

.actionPanel .MuiBadge-root {
  display: flex;
  position: relative;
  vertical-align: middle;
  height: 48px;
  align-items: center;
}
.feedbackBox .MuiBadge-badge,
.stats .MuiBadge-badge {
  background: transparent;
  font-size: 1em;
}
.orgMenu h1 {
  cursor: pointer;
}
.actionPanelBoard-orgMenu section {
  padding-top: 14px;
  border-top: 1px solid #dcdcdc;
}

.emailRetro {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  min-height: 74px;
}
.emailRetro button {
  width: 150px;
}
.actionPanelBoard .emailRetro {
  flex-direction: column;
}

@media (max-width: 480px) {
  .emailRetro {
    flex-direction: column;
  }
}

.introText {
  position: absolute;
  top: 88px;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  font-size: 1.2rem;
  color: #fff;
  pointer-events: none;
  background: radial-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.02));
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  animation: fadeIn 0.3s ease-in 3s forwards;
}

.introText div {
  width: 600px;
  margin: -40px 20px 0 20px;
  text-align: left;
}
.introText p {
  margin-bottom: 0.5rem;
}

.introText ul {
  text-align: left;
  margin: 0 0 2rem 0;
}
.voteHelpInfo {
  position: absolute;
  top: 10px;
  right: 10px;
}
.userList {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 12px;
}
.userList-info {
  text-align: left;
  flex-grow: 2;
}
.userList span {
  display: block;
}
/* .userList span:last-child {
  font-size: 0.8rem;
} */

.welcomeModal {
  text-align: center;
}

.welcomeModal h2 {
  font-size: 32px;
  font-weight: 300;
  text-indent: -12px;
}

.welcomeModal p {
  font-size: 17px;
  margin: 2rem 0;
  font-weight: 400;
}

.welcomeModal .modal-voting-img {
  height: 120px;
}

@media (max-width: 480px) {
  .welcomeModal .modal-voting-img {
    height: 90px;
  }
}

.welcomeModal li {
  line-height: 1.5rem;
  font-size: 17px;
  font-weight: 400;
}

.welcomeModal .MuiFormControlLabel-label {
  font-size: 15px;
  font-weight: 400 !important;
}

.welcomeModal .MuiFormHelperText-root {
  margin: 0;
}

.welcomeModal--list {
  margin: 34px auto;
  text-align: center;
  max-width: 422px;
}

.welcomeModal--list li {
  text-align: left;
  margin: 0 0 12px 0;
}

.votingModal-options {
  display: flex;
  justify-content: space-evenly;
}

.votingModal-options button {
  width: 210px;
  margin-bottom: 12px;
}

@media (max-width: 600px) {
  .votingModal-options {
    display: block;
  }
}

.iceBreaker p {
  text-align: left;
  margin: 1rem 12px 0;
}
.iceBreaker .iceBreaker-hint {
  text-indent: -6px;
  font-size: 0.8rem;
  margin: 0 0 1rem 6px;
  text-align: center;
}

.iceBreaker-hint .text-button {
  display: inline;
}

.iceBreaker .iceBreaker-playing {
  color: var(--pink);
  text-transform: uppercase;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
}

.iceBreaker .MuiFormHelperText-root.Mui-error {
  margin: 0.5rem 0;
  text-align: center;
  font-size: 13px;
}

.iceBreaker svg {
  margin-left: 8px;
}

.iceBreaker input {
  text-align: left;
}

.settingsPanel p {
  text-align: left;
}

.settingsPanel .text-button {
  display: inline-block;
  margin: 0px 0 0 4px;
  vertical-align: middle;
}
.helpStepper .MuiSvgIcon-root {
  color: var(--blue);
}

.votings-numbers {
  min-width: 10px;
  display: inline-block;
  text-align: right;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Tabs style */
#root .MuiTabs-root {
  text-align: center;
  color: #fff;
  min-height: auto;
  position: relative;
  z-index: 1;
  width: 100%;
}
#root .main .MuiTabs-root {
  background: var(--light-opacity);
}
#root .MuiTab-root {
  text-transform: none;
  font-size: 1rem;
  width: 248px;
  padding: 0 12px;
}

#root .MuiTab-textColorInherit {
  color: #fff;
  opacity: 0.6;
}
#root .MuiTab-textColorInherit:hover {
  opacity: 1;
}

#root .Mui-selected {
  opacity: 1;
  background: var(--dark-opacity);
  transition: background-color 1s cubic-bezier(0.4, 0, 0.2, 1) 0s, opacity 1s cubic-bezier(0.4, 0, 0.2, 1) 0s;
}

#root .MuiTabs-indicator {
  height: 4px;
  background-color: #fff;
}
.react-swipeable-view-container div[data-swipeable='true'] {
  overflow: hidden !important;
}
.MuiTabs-fixed {
  width: auto !important;
}

/*   COLUMNS   */
.board-columns-wrapper {
  text-align: center;
  padding: 0;
  position: sticky;
  top: 0;
  z-index: 1;
  background: var(--light-opacity);
  overflow-x: auto;
}

.board-columns {
  clear: both;
  max-width: 1200px;
  display: inline-flex;
  justify-content: center;
  align-items: flex-start;
  color: #fff;
  text-align: center;
  font-weight: 500;
  user-select: none;
  cursor: default;
}
@media (max-width: 1200px) {
  .board-columns {
    display: flex;
    padding: 0 20px;
  }
}
.board-columns .board-column {
  width: 228px;
  margin: 6px 7.5px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-height: 43px;
}

.board-columns .board-column:first-child {
  margin-left: 0;
}
.board-columns .board-column:last-child {
  margin-right: 0;
}
.board-columns-add-wrapper {
  text-align: center;
  align-self: normal;
}
.board-columns-add-wrapper .board-column {
  background: rgba(220, 220, 220, 0.3);
  border-radius: 10px;
  height: 44px;
  cursor: pointer;
  margin: 10px 7.5px 12px;
  transition: background 0.3s ease;
}
.board-columns-add-wrapper .board-column:hover {
  background: rgba(220, 220, 220, 0.4);
}

.board-columns-add-wrapper .board-column img {
  width: 17px;
  margin: 0 auto;
}

#root .MuiTab-root {
  min-height: 55px;
}
.board-columns-facilitator {
  cursor: pointer;
}
.board-columns-facilitator:hover .board-column:last-child h3::after {
  content: '';
  position: absolute;
  top: 6px;
  right: -50px;
  width: 18px;
  height: 18px;
  background: url(../images/icons/edit-white.svg);
}
.tabEditBtn {
  display: flex;
  align-self: center;
  width: 18px;
  height: 18px;
  margin: 0 0 0 8px;
  background: url(../images/icons/edit-white.svg);
  opacity: 0;
  cursor: pointer;
}

.MuiTabs-flexContainer:hover .tabEditBtn {
  opacity: 1;
}

#root .MuiTab-wrapper,
.board-column h3 {
  position: relative;
  font-size: 18px;
  margin: 0.5rem 0;
  font-weight: 600;
}

#root .MuiTab-wrapper span,
.board-column h3 span {
  display: block;
  font-size: 16px;
  font-weight: 500;
}

#root .actionPanelList .MuiTabs-root {
  background: transparent;
}

/**************End retro*****************/
.retro-summary {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 32px;
  min-height: 344px;
}
.retro-summary h3 {
  text-align: center;
  font-weight: 600;
}
.retro-summary p {
  font-size: 17px;
  font-weight: 400;
  line-height: 24px;
}
.retro-summary-header {
  padding: 56px 0 24px;
  min-height: 270px;
}

.retro-summary-header .teamDashboard-overview {
  justify-content: space-around;
  margin-top: 40px;
}
.retro-summary-cards .cardOverview {
  margin: 12px 0;
  padding-bottom: 4px;
}
.retro-summary-cards .cardContent {
  margin: 0;
  padding: 12px 10px 8px;
  word-break: break-word;
}
.retro-summary-actions-card {
  display: flex;
}
.retro-summary-actions-card .column-list-action {
  min-width: 20px;
  margin-left: 0;
  background-image: url(../images/icons/action.svg);
  cursor: auto;
}
.retro-summary-actions-card .column-list-action:hover {
  background-image: url(../images/icons/action.svg);
}
.retro-summary-cards,
.retro-summary-actions {
  max-width: 400px;
  margin: 0 auto;
  width: 100%;
  z-index: 1;
}
.retro-summary-header .downloadSummaryImgBtn {
  margin-left: 4px;
}

@media (max-width: 740px) {
  .retro-summary {
    grid-template-columns: 1fr;
  }
}
@media (min-width: 900px) {
  .retro-summary--2-columns {
    grid-template-columns: 1fr 2fr;
  }
  .retro-summary--2-columns .retro-summary-cards {
    width: 600px;
    max-width: none;
  }
}
/*******************************/
.board-loading-image.MuiSkeleton-root {
  position: absolute;
}
/********************************/
@media (max-width: 928px) {
  .highlightsPanel {
    display: none;
  }
}

@media (max-width: 880px) {
  .votingProgressBar {
    display: none;
  }
}

@media (max-width: 835px) {
  .brainstormingPanel {
    display: none;
  }
}

@media (max-width: 690px) {
  .exportPanel {
    display: none;
  }
}

@media (max-width: 600px) {
  .sortingPanel {
    display: none;
  }
}

@media (max-width: 780px) {
  .boardMenu h1 {
    width: 0px;
    height: 0px;
    visibility: hidden;
  }

  .boardTitlePanel .actionPanel {
    margin: 0 0 0 42px;
  }
}

@media (max-width: 540px) {
  .help {
    display: none;
  }
  .users {
    margin-right: 54px;
  }
  .actionPanel {
    padding: 0 8px;
  }
}

@media (max-width: 475px) {
  .timerProgressBar {
    display: none;
  }
}

@media (max-width: 440px) {
  .settingsPanel {
    display: none;
  }
}

@media (max-width: 420px) {
  .feedbackBox {
    display: none;
  }

  .stats {
    margin-right: 54px;
  }

  .boardMain-authenticated-false .stats {
    margin-right: 74px;
  }
}

@media (max-width: 390px) {
  .users {
    display: none;
  }
  .add-actions {
    margin-right: 54px;
  }
}

@media (max-width: 360px) {
  .boardMenu-admin {
    overflow-x: auto;
  }
}

.progressLoader .MuiCircularProgress-colorPrimary {
  color: rgba(255, 255, 255, 0.85);
}
