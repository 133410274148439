.actionPanelList-actions-hide {
  display: none;
}

.actionPanelList-actions-show {
  display: block;
}

#root .team-container .MuiTabs-root {
  background: transparent;
}

#root .MuiPaper-root .MuiTab-root {
  width: auto;
  min-height: auto;
  color: #000;
  background: transparent;
  min-width: auto;
  border-radius: 10px;
}

#root .MuiPaper-root .Mui-selected {
  background: rgba(151, 163, 255, 0.2);
}

#root .MuiPaper-root .MuiTab-wrapper span {
  color: rgba(18, 36, 62, 0.6);
  display: inline;
}

#root .MuiPaper-root .MuiTab-wrapper {
  font-size: 16px;
}

#root .team-container-header .MuiTab-root {
  width: auto;
  min-height: auto;
  min-width: auto;
  border-radius: 10px;

  padding: 0 24px;
  margin: 0 12px;
  background: rgba(32, 158, 255, 0.1);
  opacity: 1;
}
#root .team-container-header .MuiTab-wrapper span {
  display: block;
  color: rgba(18, 36, 62, 1);
  font-size: 20px;
  font-weight: 400;
}

#root .team-container-header .Mui-selected,
#root .team-container-header .MuiTab-root:hover {
  color: #fff;
  background: var(--blue);
}
#root .team-container-header .Mui-selected span,
#root .team-container-header .MuiTab-root:hover span {
  color: #fff;
}

#root .team-container-header .MuiTab-wrapper {
  color: var(--blue);
  font-size: 17px;
  font-weight: 700;
}

#root .MuiTabs-indicator {
  display: none;
}

.actions-container {
  margin: 18px 4px 12px;
}

.MuiPaper-root textarea {
  font-size: 17px;
  font-weight: 400;
}
.MuiPaper-root .actions-container-input .MuiFormControl-root {
  width: 100%;
  min-height: 76px;
  margin: 0;
}
.MuiPaper-root .column-list .MuiFormControl-root {
  margin: 0;
  width: 100%;
}

.MuiPaper-root .MuiListItem-secondaryAction {
  padding-left: 0;
}

.MuiPaper-root .MuiListItemSecondaryAction-root {
  right: 12px;
  top: 16px;
}

.new-action-field-helper {
  font-size: 14px !important;
  position: absolute;
  right: 0;
  bottom: -4px;
}
.new-comment-field-helper {
  font-size: 14px !important;
  position: absolute;
  right: 0;
  bottom: -24px;
}
/* task transition animations */

.open-animation-enter,
.resolve-animation-enter {
  opacity: 0.01;
  /*height: 0;*/
  transform: translateY(-100%);
}

/*
.open-animation-enter .column-list-action {
  opacity: 0;
}
.open-animation-enter.open-animation-enter-active .column-list-action {
  opacity: 1;
  transition: opacity 100ms ease-in-out;
}
*/

.open-animation-enter.open-animation-enter-active,
.resolve-animation-enter.resolve-animation-enter-active {
  opacity: 1;
  /*height: 35px;*/
  transform: translateY(0);
  transition: all 500ms cubic-bezier(0.02, 0.58, 0.61, 0.94);
}

.open-animation-exit,
.resolve-animation-exit {
  opacity: 1;
  transform: translateX(0);
}

.open-animation-exit.open-animation-exit-active,
.resolve-animation-exit.resolve-animation-exit-active {
  opacity: 0.01;
  transform: translateX(100%);
  transition: 500ms;
}

.resolve-animation-exit.resolve-animation-exit-active {
  opacity: 0.01;
  transform: translateX(-100%);
  transition: 500ms;
}

.column-list .MuiInputBase-root {
  display: flex;
}
.column-list {
  min-height: 42vh;
  height: calc(100vh - 254px);
  overflow-y: auto;
  overflow-x: hidden;
}
.column-list .MuiSelect-selectMenu {
  padding: 1px 0 0;
}

@supports (-webkit-touch-callout: none) {
  .column-list {
    height: calc(100vh - 368px);
  }
}
.column-list li {
  margin-bottom: 4px;
}
.action-lists {
  margin: 0 -16px 0;
}

.column-list-done textarea {
  text-decoration: line-through;
}

.column-list-open .MuiListItem-root:hover .column-list-action svg {
  opacity: 0.6;
  fill: green;
}
.column-list-action {
  display: inline-flex;
  cursor: pointer;
  width: 25px;
  height: 25px;
  margin-right: 12px;
  background-image: url(../../images/icons/todo.svg);
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  align-self: start;
  margin-top: 0;
  margin-left: 16px;
}

.column-list-action:hover {
  background-image: url(../../images/icons/done.svg);
}

.column-list-action-resolved {
  background-image: url(../../images/icons/done.svg);
}
.column-list-action-resolved:hover {
  background-image: url(../../images/icons/todo.svg);
}

.MuiListItem-root:hover {
  background: #f2f2f2;
}

.MuiListItem-root .close {
  opacity: 0;
}

.MuiListItem-root:hover .close {
  opacity: 1;
  transition: opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1), background 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

/***************Tabs******************/

.customTabs .MuiTabs-root {
  text-align: center;
  background: transparent;
  color: #fff;

  min-height: auto;
  position: relative;
  z-index: 1;
  width: 100%;
}

.customTabs-secondary .MuiTab-root {
  width: auto;
  min-height: auto;
  color: #000;
  background: transparent;
  min-width: auto;
  border-radius: 4px;
  text-transform: none;
}

.customTabs-secondary .Mui-selected {
  background: rgba(151, 163, 255, 0.2);
}

.customTabs-secondary .MuiTab-wrapper {
  font-size: 16px;
}

.customTabs-secondary .MuiTab-wrapper span {
  color: rgba(18, 36, 62, 0.6);
  display: inline;
}

.customTabs-secondary .MuiTabs-indicator {
  display: none;
}
