.createBoard {
  max-width: 1200px;
  margin: 0 auto;
}
.createBoard h2 {
  margin: 0 0 1rem 0;
}
.form {
  padding: 0 10px;
}

.label {
  display: block;
  padding: 10px 0;
  font-weight: 600;
}

.field {
  margin: 0 0 12px 0;
  overflow-x: auto;
  overflow-y: hidden;
}

.field h3 {
  text-align: center;
  margin: 0 0 1rem 0;
  font-weight: 500;
}
.field p {
  text-align: center;
}

.action {
  text-align: right;
}

.retroCols {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.retroCols .MuiFormControl-root {
  width: 100%;
  margin: 0.5rem 0 0.5rem 0;
}

.newBoardCols {
  width: 100%;
}

/* .retroCols-tabs {
  grid-template-columns: auto !important;
  grid-auto-flow: column;
} */

.retroFormats {
  text-align: center;
  position: fixed;
  bottom: 16px;
}

.retroSettings-columns-layout {
  display: grid;
  grid-template-columns: 1.2fr 1fr;
}

.retroFormats .MuiToggleButtonGroup-root {
  background: transparent;
  overflow-x: scroll;
  width: 100vw;
}

.retroFormats .MuiToggleButton-root {
  margin: 0 16px;
  padding: 8px 0 0 0;
  font-weight: 300;
  border: none 0;
  box-sizing: border-box;
  transition: transform 0.2s ease;
}

.retroFormats .MuiToggleButton-root:hover,
.retroFormats .MuiToggleButton-root.Mui-selected,
.retroFormats .MuiToggleButton-root.Mui-selected:hover {
  background: transparent;
}
.retroFormats .MuiToggleButton-root:hover,
.retroFormats .MuiToggleButton-root.Mui-selected:hover {
  background: transparent;
}

#root .retroFormats .MuiToggleButton-root.Mui-selected {
  background: transparent;
}

/* .retroFormats .MuiToggleButton-root:last-child::before {
  content: 'new';
  position: absolute;
  top: 6px;
  text-transform: none;
  font-weight: 400;
} */

.retroFormats .MuiToggleButton-label {
  display: block;
  font-size: 1em;
  text-transform: none;
  font-weight: 300;
}

.retroFormats h4 {
  margin: 6px 0;
  font-size: 16px;
  font-weight: 600;
}

.retroFormats p {
  margin: 0 0 0.5rem 0;
  text-align: left;
}

.retroLayout h4 {
  text-align: center;
}

.retroLayout-container {
  display: flex;

  align-items: center;

  flex-direction: column;
}

.retroLayout-container .MuiFormGroup-row {
  justify-content: center;
}

.retroSettings {
  position: fixed;
  right: 32px;
  bottom: 164px;
  padding: 24px 24px 0 24px;
  width: 300px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
}

.retroSettings h2 {
  margin: 0 0 1.5rem 0;
  font-size: 1.2rem;
  text-align: center;
}

.retroSettings label.label {
  padding: 0 0 8px 0;
  font-size: 0.9rem;
  font-weight: 700;
  display: flex;
  cursor: pointer;
}

.retroSettings label.label:hover svg {
  opacity: 1;
}

.retroSettings label.label svg {
  margin: 0 8px;
  opacity: 0.6;
}
.retroSettings-field .MuiFormControl-root {
  width: 100%;
}

.retroSettings-field {
  margin: 0 0 24px 0;
}
.retroSettings-field p {
  margin-top: 0;
  cursor: pointer;
}
.retroSettings-field p span {
  display: block;
}
.retroSettings-field:last-child {
  text-align: right;
  margin: -18px 0 24px 0;
}
.backgroundThumbnail {
  background-position: center center;
  background-size: cover;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.retroSettings-columns-base {
  margin-bottom: -20px;
  margin-top: -20px;
}
.customBgField {
  height: 456px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px 0 0 0;
}
.customBgField p {
  text-align: left;
}

.customBgField-label {
  margin: 24px 0 6px;
  font-weight: 500;
}

.customBgField .MuiTextField-root {
  width: 100%;
  margin: 0 0 12px 0;
}

.customBgField .text-button {
  display: inline-block;
  margin: 0;
}

/*******************Retro format*******************/

.retro-format .retro-format-card {
  position: relative;
  text-align: center;
  color: var(--color-dark);
  overflow: hidden;
  border-radius: 5px;
  transition: transform 0.2s ease;
  width: 160px;
  height: 120px;
  box-shadow: 0px 3px 10px 0px #00000040;
}

.retro-format .retro-format-card:hover {
  transform: translate(0, -8px);
  text-decoration: none;
}

.retro-format-card img {
  display: block;
  padding: 0;
  margin: 0;
}

.retro-format-card h3 {
  background: rgba(0, 0, 0, 0.8);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: 14px;
  margin: 0;
  padding: 7px 5px;
  color: #fff;
  border-radius: 5px;
}

.retro-format-info {
  position: absolute;
  top: 4px;
  right: 4px;
  color: white;
  padding: 2px 10px;
  background: rgba(22, 22, 22, 0.2);
  box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.1);
  border-radius: 36px;
  opacity: 0;
  transition: opacity 0.5s ease, background 0.2s ease;
}

.retro-format:hover .retro-format-info {
  opacity: 1;
}

.retro-format-info:hover {
  background: rgba(22, 22, 22, 0.7);
}

/************************************************************/
.newBoard {
  background: var(--light-grey);
}
.newBoard .boardMain {
  height: 100vh;
  min-height: 740px;
  overflow-y: auto; /* Do not remove this */
}
.newBoard .boardMenu {
  position: relative;
  z-index: 1;
}
.retroBot-input-field:disabled {
  opacity: 0.5 !important;
}

@media (max-width: 810px) {
  .retroFormats .MuiToggleButton-root {
    min-width: auto;
  }
  .retroFormats h4 {
    margin: 0;
  }
  .retroFormats .MuiToggleButton-root:last-child::before {
    top: -3px;
  }
}

@media (max-width: 760px) {
  .retroSettings-columns-layout {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 412px) {
  .retroSettings {
    bottom: 120px;
    right: 0;
    margin: 0 12px;
    width: calc(100% - 48px - 24px);
  }
}

/******************** Image grid ************************/
.image-grid {
  display: grid;
  grid-gap: 30px 15px;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  /*grid-auto-rows: minmax(50px, 150px);*/
}

.image-grid--withLink {
  grid-gap: 0 15px;
}

.image-grid--image-wrapper {
  width: 150px;
  height: 150px;
  display: block;
  overflow: hidden;
  position: relative;
  border-radius: 50%;
  cursor: pointer;
}

.image-grid img {
  height: 100%;
}

.image-grid--col a {
  margin: 4px auto 8px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  color: var(--color-dark);
  text-decoration: none;
  font-weight: 500;
  visibility: hidden;
}

.image-grid--col a:hover {
  text-decoration: underline;
}

.image-grid--col:hover a {
  visibility: visible;
}

@media (max-width: 480px) {
  .image-grid {
    grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
  }

  .image-grid--image-wrapper {
    width: 100px;
    height: 100px;
  }
}

/******************** Create Pulse ************************/
.boardMain-pulse {
  background-image: url('images/img/pulse-background.png');
}
.boardMain-pulse .main {
  background: none;
}

.boardMain-pulse .retroSettings {
  bottom: auto;
  right: auto;
  padding: 24px;
  background: rgba(255, 255, 255, 0.7);
}
.newBoard .pulseEditorArea {
  background: rgba(255, 255, 255, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 960px) {
  .boardMain-pulse .retroSettings {
    width: calc(100% - 72px);
    margin: 24px;
    position: static;
  }
}
@media (min-width: 961px) {
  .newBoard .PulseEditorWrapper {
    height: calc(100% - 48px);
  }
}
