:root {
  --blue: #209eff;
  --light-blue: #bce2ff;
  --red: #f26c7c;
  --pink: #ff68a8;
  --orange: #ffa033;
  --purple: #5365f0;
  --light-grey: #a7a7a7;
  --dark-opacity: rgba(0, 0, 0, 0.8);
  --light-opacity: rgba(0, 0, 0, 0.6);
  --color-background: #fdfdfd;
  --color-dark: #12243e;
}

body {
  margin: 0;
  font-size: 1rem;
  background-color: #fdfdfd;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: middle;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

p {
  font-weight: 500;
}

textarea {
  background: transparent;
  border: 0 none;
  width: 100%;
  color: #000;
  font-size: 1.1rem;
  padding: 0;
  line-height: 1.4;
  resize: none;
}

textarea:focus,
textarea:active {
  outline: none;
  border: none;
}

code {
  display: block;
  font-family: 'Courier', 'Andale Mono', 'Ubuntu Mono', monospace;
  font-size: 0.9em;
  background-color: #000;
  color: #fff;
  padding: 6px 12px;
  margin: 12px 0;
  border-radius: 5px;
  white-space: nowrap;
  line-height: 2;
  overflow: auto;
}

.text-button {
  cursor: pointer;
  color: var(--blue);
  margin-left: 8px;
  margin-right: 8px;
  font-weight: 600;
  text-decoration: none;
  transition: text-decoration 0.3s cubic-bezier(0.4, 0, 1, 1) 0s;
}

.text-button:hover {
  text-decoration: underline;
}

.text-button-logout {
  border-top: 1px solid #dcdcdc;
  padding-top: 12px;
  margin-top: 16px !important;
}

.text-button + button {
  margin-left: 24px;
}

.link-button {
  display: inline-block;
  cursor: pointer;
  padding: 8px 15px;
  text-decoration: none;
  border: none;
  background: var(--color-dark);
  box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  font-family: Quicksand;
  font-style: normal;
  font-weight: 500;
  font-size: 1.0625rem; /* 17px */
  color: white;
  transition: all 0.2s ease-in;
}

.link-button:hover {
  text-decoration: none;
  filter: brightness(101%);
  transform: scale(1.04);
}

.assignees-button {
  text-align: center;
  display: block;
  color: var(--blue);
  font-size: 14px;
  margin: 12px 0 4px;
  font-weight: 600;
  text-decoration: none;
}

.assignees-button:hover {
  text-decoration: underline;
}

hr {
  width: 120px;
  height: 0;
  border: none;
  margin: 2.3rem auto;
  border-bottom: 1px solid var(--blue);
}

#root .loginBtn {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 14px;
  z-index: 20;
  color: #fff;
  text-decoration: none;
  user-select: none;
  font-weight: 500;
}

.home {
  color: #fff;
  position: absolute;
  left: 5px;
  top: 9px;
  /* z-index: 20; */
}
.home svg {
  width: 38px;
  height: 35px;
}

.home-christmas::after {
  content: url('images/img/hat.png');
  position: absolute;
  left: -27px;
  top: -24px;
  transform: scale(0.5);
}

.profile {
  position: absolute;
  top: 8px;
  right: 12px;
  z-index: 20;
}

.profile img {
  cursor: pointer;
}

#root .profileImg {
  width: 32px;
  height: 32px;
}

.menu {
  color: #fff;
  height: 48px;
  background: rgba(0, 0, 0, 0.8);
  /*backdrop-filter: blur(3px);* need to fix click event
  will-change: auto;*/
}

/* .menu::before {
  position: absolute;
  content: '';
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(
    to right top,
    #6789e8,
    #6e85e3,
    #7481de,
    #797ed9,
    #7e7ad4,
    #8277cf,
    #8673ca,
    #8970c5,
    #8c6cbf,
    #8f68ba,
    #9165b4,
    #9361ae
  );
  transition: opacity 0.5s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  opacity: 0;
}

.menu.vote::before {
  opacity: 1;
}*/

.overview {
  height: 100vh;
  width: 100%;
}

.overview-fix {
  overflow: hidden;
}

.pageNav {
  padding: 0 36px;
}

.pageNav .MuiList-root {
  display: flex;
  padding: 0;
}

.pageNav .currentNav {
  background: #fff;
}

.pageNav .MuiListItem-root {
  width: auto;
  padding: 0 16px 0 0 !important;
}

.pageNav .MuiListItem-root a {
  text-decoration: none;
  padding: 4px 16px;
}

.pageNav .MuiListItem-root svg {
  float: right;
  margin: 5px 0 0 5px;
}
.pageNav .MuiListItemText-root {
  float: left;
}

.pageNav .MuiTypography-body1 {
  font-family: 'Quicksand', 'Roboto', 'Helvetica', 'Arial', 'sans-serif';
  font-weight: 500;
  color: #000;
}
.page {
  position: relative;
  min-height: 100vh;
}

.pageMain {
  padding: 36px;
  background: transparent;
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 48px - 40px - 36px - 36px);
}

.pageMain > div {
  max-width: 1128px;
  width: 100%;
}

.boardMenu {
  color: #fff;
  height: 48px;
  font-weight: 500;
  background: var(--dark-opacity);
}

.boardMenu-admin {
  background: var(--light-opacity);
  margin-bottom: 0;
  clear: both;
}

.boardCard {
  width: 260px;
  margin: 12px 8px;
  flex: 0 0 auto;
  position: relative;
  /*transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0ms;*/
}
.boardCard:hover {
  /*transform: translate(0, -1px);*/
}
.boardCard .MuiCardContent-root {
  padding: 0;
}
.boardCard .MuiCardContent-root a {
  display: block;
  padding: 16px;
  text-decoration: none;
  font-weight: 500;
}

.boardCard .MuiCardContent-root a,
.boardCard .MuiCardContent-root a:visited,
.boardCard .MuiCardContent-root a:active {
  color: #000;
}
.boardCard-content {
  padding: 16px 8px 12px;
  min-height: 20px;
}

.boardCard-content a {
  display: block;
  font-weight: 500;
}

.boardCard-content a,
.boardCard-content a:visited,
.boardCard-content a:active {
  color: #000;
  text-decoration: none;
}

.newBoardBtn {
  float: left;
}

.newBoardBtn a {
  height: 170px;
  display: flex;
  flex-direction: column;
  color: rgba(0, 0, 0, 0.5);
  border: 1px dashed rgba(0, 0, 0, 0.2);
  transition: color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  align-items: center;
  border-radius: 4px;
  justify-content: center;
  font-weight: 600;
}

.newBoardBtn a:hover {
  color: rgba(0, 0, 0, 1);
  border: 1px dashed rgba(0, 0, 0, 1);
}

.boardCard-media {
  height: 80px;
}

.boardCard-actions {
  display: flex;
  justify-content: space-between;
}

.boardCard-actions p {
  margin: 0;
  font-size: 0.9rem;
}

.boardCard-actions .boardCard-actions-btn {
  padding: 4px;
}

.boardCard-actions .MuiMenuItem-root {
  min-height: auto;
  font-weight: 500;
}

.actionCopyBtn {
  position: absolute;
  top: 3px;
  right: 12px;
}
.actionCopyBtn svg {
  width: 18px;
  height: 18px;
}

.timerProgressBar {
  float: right;
  padding: 0 15px;
  user-select: none;
}

.timerProgressBar p {
  margin: 0;
  line-height: 48px;
}

.timerProgressBar .timeElement {
  width: 20px;
  display: inline-block;
  text-align: left;
  padding: 0 2px;
}

.timerProgressBar .timeElement-mins {
  text-align: right;
}

/* Account */
.plan-actions {
  margin: 40px 0;
}
.plan-modal {
}

.MuiDialog-root h2 {
  margin: 0 0 1rem 0;
}

.plan-modal .plan-fieldset {
}

.MuiDialog-root .MuiDialog-paperWidthSm {
  max-width: 592px; /* 764px; */
}

.plan-modal .MuiFormControl-root,
.plan-modal .MuiInput-root {
  width: 100%;
}

.plan-modal-content {
  padding: 0 16px 0 0;
}

.plan-modal-actions {
  text-align: right;
  margin: 2rem 0 0 0;
}

.stripeRedirectBtn .text-button {
  display: inline-flex;
  justify-content: center;
}

.stripeRedirectBtn svg {
  margin: 0 4px;
}

.footer {
  padding: 10px 0;
  background: rgba(0, 0, 0, 0.8);
}

.footer ul {
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer .text-button {
  color: #fff;
}

/***************** Teams Dashboard *************************/
.promoLink {
  position: absolute;
  top: 58px;
  right: 24px;
}

.promoLink span {
  margin-left: 0;
}

.retros-count-container {
  width: 178px;
  background: lightgrey;
  height: 15px;
  border-radius: 20px;
  margin: 0 0 6px 0;
}

.retros-count-numbers {
  margin-bottom: 6px;
}

.retros-count-bar {
  background: var(--blue);
  height: 100%;
  border-radius: 20px;
}
.org-dashboard-button {
  position: absolute;
  top: 100px;
  left: 38px;
}
@media (max-width: 920px) {
  .retros-count {
    display: inline-flex;
  }

  .retros-count-container {
    margin: 2px 0 0 12px;
  }
  .promoLink span.text-button {
    margin-left: 12px;
  }

  .org-dashboard-button {
    text-align: left;
    position: static;
  }
}

@media (max-width: 600px) {
  .retros-count-container {
    display: none;
  }
}

@media (max-width: 400px) {
  .retros-count {
    display: none;
  }
}

.team-container-header {
  padding-top: 10px;
  text-align: center;
  margin: 0 0 2rem 0;
}

.team-container-header h2 {
  margin: 0;
  font-weight: 300;
  font-size: 35px;
  line-height: 62px;
}

.team-container-header h2 svg {
  width: 14px;
  height: 14px;
  margin-left: 12px;
}
.teamsDropdown {
  min-width: 240px;
}

.team-container-header p {
  font-size: 20px;
  font-weight: 400;
  margin: 0.5em;
}

/***************** Team Dashboard *************************/
.teamDashboard h2 {
  font-size: 19px;
  font-weight: 500;
  padding: 0;
  margin: 0;
  text-align: center;
}
.teamDashboard {
  padding: 0 0 24px 0;
}

.teamDashboard .MuiPaper-root {
  width: 100%;
}

.teamDashboard-grid {
  position: relative;
  padding: 24px 20px;
  min-height: 326px;
  margin-bottom: 12px;
}
.teamDashboard-grid-2 {
  min-height: 384px;
}
.teamDashboard-grid p {
  font-weight: 400;
  line-height: 175%;
}
.teamDashboard .cardContent {
  font-size: 17px;
  line-height: 1.4em;
  overflow-wrap: anywhere;
  margin-bottom: 4px;
}
.prevBoard h3,
.topCards h3,
.nextRetro h3 {
  margin: 1rem 0 4px;
  font-size: 14px;
  font-weight: 600;
}

.teamDashboard-grid .actions-container {
  margin: 24px 0 0;
}

.teamDashboard-grid .action-lists {
  margin: 0 -20px 0;
}

.teamDashboard-grid .column-list {
  height: auto;
  min-height: auto;
  max-height: 548px;
}

.nextRetro {
  position: relative;
}

.nextRetro .MuiMobileStepper-root button {
  position: absolute;
  bottom: 86px;
  left: -20px;
  padding: 4px !important;
}
.nextRetro .MuiMobileStepper-root button:last-child {
  right: -20px;
  left: auto;
}
.topCards h3 {
  text-align: center;
  margin: 3em 0 1em;
}
.prevBoard a {
  color: #000;
}
.prevBoard p {
  margin: 0 0 1rem 0;
}
.teamDashboard-overview {
  display: flex;
  justify-content: space-between;
  text-align: center;
  margin-top: 18px;
}

.teamDashboard-overview span span {
  display: block;
  margin: 0 0 6px 0;
  font-size: 14px;
  font-weight: 600;
}

.teamDashboard-overview .teamDashboard-overview-icon {
  font-size: 24px;
}

.topCards {
  margin: 0 -20px;
}

.topCards-content {
  max-height: 425px;
}

.cardOverview {
  position: relative;
}
.cardContent {
  text-align: center;
  margin: 20px 20px 0 20px;
}
.cardVotesCount {
  color: #fff;
  background: var(--blue);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  line-height: 20px !important;
  text-align: center;
  align-self: center;
  margin: 4px;
  font-size: 14px !important;
}

.graphContainer {
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  height: 100px;
  padding: 150px 0 0;
  overflow-x: auto;
}

.graphContainer-item {
  display: flex;
  min-width: 29px;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.5s ease-in-out;
  background: #fff;
}

.graphContainer-item--dot {
  /* margin: 0 auto -11px; */
  width: 11px;
  height: 11px;
  background-color: var(--blue);
  border-radius: 50%;
}

.graphContainer-item--bar {
  width: 10px;
  flex-grow: 1;
  background-color: var(--blue);
}

.graphContainer-item--info {
  margin: 4px auto 4px auto;
  width: 9px;
  height: 9px;
  background-color: rgba(179, 179, 179, 0.2);
  border-radius: 50%;
}
.graphContainer-item--dot,
.graphContainer-item--bar,
.graphContainer-item--info {
  display: block;
  transition: transform 0.3s ease-out;
}
.graphContainer-item--interactive .graphContainer-item--info {
  background-color: rgba(179, 179, 179, 0.8);
}
.graphContainer-item--noninteractive,
.graphContainer-item--interactive {
  display: flex;
  width: 20px;
  height: 20px;
  align-items: flex-end;
  justify-content: center;
}

.graphContainer-item--noninteractive .graphContainer-item--dot {
  margin: 0 auto -11px;
}

.graphContainer-item--interactive {
  cursor: pointer;
}

.graphContainer-item--interactive:hover .graphContainer-item--dot,
.graphContainer-item--interactive:hover .graphContainer-item--info {
  transform: scale(1.5);
}
.graphContainer-item--tooltip {
  text-align: center;
  padding: 8px 0 4px;
}
.graphContainer-item--tooltip h3 {
  font-size: 17px;
  font-weight: 500;
  margin: 0 0 12px 0;
}
.graphContainer-item--tooltip h4 {
  margin: 0;
  font-weight: 500;
}
.graphContainer-item--tooltip a {
  color: #fff;
  text-decoration: none;
  display: flex;
  justify-content: center;
  margin-top: 10px;
  font-size: 16px;
  font-weight: 400;
}
.graphContainer-item--tooltip a svg {
  width: 14px;
  padding: 0 0 0px 4px;
  margin-top: -1px;
}
.graphContainer-item--tooltip a svg path {
  fill: #fff;
}
.graphContainer-item--tooltip a:hover {
  text-decoration: underline;
}

.graphContainer-item--tooltip .cardContent {
  font-size: 15px;
  font-weight: 400;
}

.graphContainer-item--content {
  margin: 0 0 6px 0;
}

.graphContainer-item--content span {
  display: block;
  line-height: 1.5;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
}
.graphContainer-item--content--columns span {
  text-align: left;
}
.graphContainer-item--content h3 span {
  font-size: 14px;
  font-weight: 500;
  padding-top: 4px;
}

.graphContainer-item--content b {
  padding: 0 6px;
}

.graphGroups,
.menuGroups {
  display: flex;
  justify-content: center;
  align-items: center;
}

.menuGroups {
  flex-direction: column;
}

.graphGroups button {
  margin: 24px 4px 0;
}

.menuGroups button {
  margin: 0 0 8px 0;
}

.graphGroups button,
.menuGroups button {
  background: rgba(32, 158, 255, 0.1);
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: var(--blue);
  padding: 5px 10px;
  height: 28px;
  border: none;
  outline: none;

  cursor: pointer;
  font-family: 'Quicksand', 'Roboto', 'Helvetica', 'Arial', 'sans-serif';
}

.graphGroups button:hover,
.graphGroups button:focus,
.graphGroups button:active,
.graphGroups .graphGroups-group-selected,
.menuGroups button:hover,
.menuGroups button:focus,
.menuGroups button:active,
.menuGroups .menuGroups-group-selected {
  border: none;
  outline: none;
  background: var(--blue);
  color: #fff;
}

.searchGroup-results {
  max-height: 452px;
  margin: 44px -20px 0 -20px;
}

.searchGroup-results--icebreaker {
  font-weight: 500;
  margin: 24px auto;
  padding: 24px;
  text-align: center;
  max-width: 1000px;
}

.searchGroup-results--icebreaker .searchGroup-results {
  min-height: 210px;
  max-height: 800px;
}

.searchGroup-results--icebreaker .icebreaker-list {
  margin: 20px 12px;
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  grid-column-gap: 24px;
}

@media (max-width: 1000px) {
  .searchGroup-results--icebreaker .icebreaker-list {
    grid-template-columns: 1fr 3fr;
  }
}
.searchGroup-results--icebreaker .icebreaker-list > div:first-child {
  display: inline-flex;
  align-items: flex-start;
  word-break: break-word;
  justify-content: flex-end;
}

.searchGroup-results--icebreaker .icebreaker-list > div:last-child {
  display: inline-flex;
}

.searchGroup-results--icebreaker--result {
  background-color: var(--blue);
  border-radius: 25px;
  padding: 13px 15px;
  color: #fff;
  text-align: left;
  word-break: normal;
}
.searchGroup-results--icebreaker--result--unsafe {
  color: var(--color-dark);
  background-color: #eee;
  width: 86%;
}
.searchGroup-results--icebreaker .icebreaker-list button {
  opacity: 0;
  transform: scale(0.8);
  height: 44px;
  align-self: center;
}
.searchGroup-results--icebreaker .searchGroup-results--icebreaker--wrapper:hover button {
  opacity: 1;
}

.panelAction--search {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.teamDashboard-search {
  position: absolute;
  right: 22px;
}

@media (max-width: 1000px) {
  .panelAction--search {
    flex-direction: column;
  }
  .teamDashboard-search {
    position: static;
    margin: 12px 0 0 0;
  }
}

.retroSearch {
  width: 360px;
  text-align: left;
}

.retroSearch .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input {
  padding: 3px 4px;
}
.retroSearch .search-field-helper {
  position: absolute;
  bottom: -12px;
  font-size: 16px;
  font-weight: 600 !important;
  text-align: center;
  margin: 0 auto;
  width: 100%;
  color: #000;
  z-index: 10;
}

.retroSearch .info-text {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 104px;
}

.retroSearch .cardContent {
  margin: 0 !important;
  padding: 9px 20px;
  text-align: left;
}
.retroSearch .cardContent a {
  display: block;
  word-break: break-word;
}
.retroSearch .cardContent a,
.retroSearch .cardContent a:hover,
.retroSearch .cardContent a:active,
.retroSearch .cardContent a:focus,
.retroSearch .cardContent a:visited {
  color: #000;
  text-decoration: none;
}

.retroSearch .cardOverview {
  position: relative;
  width: 360px;
}

.retroSearch .cardOverview:hover .cardContent {
  /*background: rgba(220, 220, 220, 0.2);*/
  cursor: pointer;
}

.retroSearch .cardOverview span {
  position: absolute;
  top: 0;
  right: 0;
  visibility: hidden;
  text-decoration: none;
  display: flex;
  justify-content: center;
  font-size: 16px;
  font-weight: 400;
  height: 100%;
  align-items: center;
}
.retroSearch .cardOverview:hover span {
  visibility: visible;
}
.contentScroll-top {
  position: absolute;
  top: 0;
  width: 100%;
  border-bottom: 1px solid #dcdcdc;
}

.contentScroll-down {
  position: absolute;
  bottom: 0;
  width: 100%;
  border-bottom: 1px solid #dcdcdc;
}

@media (max-width: 420px) {
  .graphContainer {
    justify-content: flex-start !important;
  }
}

/*----- Clouds -----*/

.clouds {
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
}

.cloud {
  position: absolute;
}

.cloud-small {
  height: 44px;
  width: 60px;
  background: url('https://www.teleretro.com/img/clouds/cloud-small-blue.png') no-repeat;
}

.cloud-1 {
  height: 76.6px;
  width: 108px;
  background: url('https://www.teleretro.com/img/clouds/cloud-1-blue.png') no-repeat;
}

.cloud-2 {
  height: 108px;
  width: 168px;
  background: url('https://www.teleretro.com/img/clouds/cloud-2-blue.png') no-repeat;
}

.cloud-small.cloud-purple {
  background: url('https://www.teleretro.com/img/clouds/cloud-small-purple.png') no-repeat;
}

.cloud-small.cloud-pink {
  background: url('https://www.teleretro.com/img/clouds/cloud-small-pink.png') no-repeat;
}

.cloud-small.cloud-orange {
  background: url('https://www.teleretro.com/img/clouds/cloud-small-orange.png') no-repeat;
}

.cloud-1.cloud-purple {
  background: url('https://www.teleretro.com/img/clouds/cloud-1-purple.png') no-repeat;
}

.cloud-1.cloud-pink {
  background: url('https://www.teleretro.com/img/clouds/cloud-1-pink.png') no-repeat;
}

.cloud-1.cloud-orange {
  background: url('https://www.teleretro.com/img/clouds/cloud-1-orange.png') no-repeat;
}

.cloud-2.cloud-purple {
  background: url('https://www.teleretro.com/img/clouds/cloud-2-purple.png') no-repeat;
}

.cloud-2.cloud-pink {
  background: url('https://www.teleretro.com/img/clouds/cloud-2-pink.png') no-repeat;
}

.cloud-2.cloud-orange {
  background: url('https://www.teleretro.com/img/clouds/cloud-2-orange.png') no-repeat;
}

.cloud-3.cloud-purple {
  background: url('https://www.teleretro.com/img/clouds/cloud-3-purple.png') no-repeat;
}

.cloud-3.cloud-pink {
  background: url('https://www.teleretro.com/img/clouds/cloud-3-pink.png') no-repeat;
}

.cloud-3.cloud-orange {
  background: url('https://www.teleretro.com/img/clouds/cloud-3-orange.png') no-repeat;
}

@media (max-width: 500px) {
  .clouds .cloud:first-child {
    display: none;
  }
}
/****************** Boards ********************/
.teamBoardsWrapper {
  padding: 24px 0;
  margin: 0px 0 36px 0;
}
.teamBoardsContainer {
  display: flex;
  flex-wrap: wrap;
  padding: 0 12px;
}

.panelAction {
  text-align: center;
  padding: 0 0 12px 0;
}

@media (max-width: 1180px) {
  .teamBoardsContainer {
    justify-content: center;
  }
}

/****************** Team ********************/
.team-container .MuiTableCell-root {
  padding: 8px 16px;
  border-bottom: none 0;
  font-size: 1em;
}

#root .team-container th.MuiTableCell-root {
  font-weight: 700;
}

.memberList {
  width: 500px;
}

.memberList .userList-info {
  margin-right: 12px;
}
.teamMembers {
  margin: 24px auto 48px;
  padding: 24px;
}

/****************** Org dashboard ********************/
.teamDashboard-grid--org {
  min-height: auto;
  padding-bottom: 40px;
  overflow: auto;
}

.teamDashboard-grid--org h2 {
  padding-bottom: 28px;
}

.teamDashboard-grid--org table {
  width: 100%;
}
.teamDashboard-grid--org table thead th {
  height: 50px;
  font-size: 14px;
  font-weight: 600;
  padding: 0 4px;
}
.teamDashboard-grid--org table thead th:nth-child(1) {
  width: 200px;
}
.teamDashboard-grid--org table thead th:nth-child(2) {
  width: 550px;
}
.teamDashboard-grid--org table tbody th {
  text-align: left;
  display: flex;
}
.teamDashboard-grid--org table tbody th .text-button {
  font-size: 19px;
  font-weight: 500;
  white-space: nowrap;
}
.teamDashboard-grid--org table td {
  text-align: center;
  height: 50px;
  font-size: 18px;
}
.teamDashboard-timeline {
  display: flex;
  justify-content: center;
  min-width: 560px;
  margin: 0 auto;
}
.teamDashboard-timeline svg {
  overflow: visible;
}
.teamDashboard-timeline svg > g:first-child g g {
  display: none;
}

.teamDashboard-timeline svg > g:first-child g text + text {
  display: none;
}
/**** Note: css transform only work on svg/g in safari, not on text directly. ****/
.teamDashboard-timeline svg > g:first-child {
  transform: translate(0, calc(-100% + 30px));
}
.teamDashboard-timeline svg > g:first-child g line {
  transform: translate(0, calc(100% - 30px));
}
.teamDashboard-timeline svg > g:first-child g text {
  font-family: 'Quicksand', 'Roboto', 'Helvetica', 'Arial', 'sans-serif';
  font-weight: 500 !important;
  opacity: 1 !important;
  color: #12243e !important;
  font-size: 12px !important;
  text-transform: uppercase !important;
  /*transform: translate(0, calc(-100% + 12px));*/
}
.teamDashboard-timeline svg > g:first-child line {
  stroke: #e9e7ec !important;
}

.teamDashboard-timeline .graphContainer-item--interactive {
  align-items: center;
}

/**** Note: css transform causing bug in safari, use size instead scale for animation ****/
.teamDashboard-timeline .graphContainer-item--dot {
  transition: width 0.3s ease-out, height 0.3s ease-out;
}

.teamDashboard-timeline .graphContainer-item--interactive:hover .graphContainer-item--dot {
  transform: none;
  width: 16px;
  height: 16px;
}
.teamDashboard-grid--org table .MuiAvatarGroup-avatar {
  width: 28px;
  height: 28px;
  border: none;
  font-size: 14px;
  font-weight: 500;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  background: #12243e;
}
.teamDashboard-grid--org table .MuiSkeleton-root {
  margin: 0 auto;
}
.createTeamButton--org {
  position: absolute;
  top: 24px;
  right: 24px;
  height: 28px;
  line-height: 28px;
}

@media (max-width: 600px) {
  .createTeamButton--org {
    position: static;
    text-align: right;
    margin-bottom: 32px;
  }
}
@media (max-width: 380px) {
  .createTeamButton--org {
    margin-top: 32px;
  }
}
/****************** Integrations ********************/
.integrations-zapier-wrapper {
  padding: 24px 48px;
}

.integrations-zapier-wrapper h2 {
  text-align: center;
  font-size: 35px;
  line-height: 1.75em;
  font-weight: 300;
}
.integrations-zapier-wrapper h3 {
  font-size: 20px;
  text-align: center;
  font-weight: 600;
  margin: 2em 0 0 0;
}
.integrations-zapier-wrapper p {
  font-weight: 400;
  line-height: 1.75em;
}

.integrations-zapier-wrapper ol {
  padding: 0;
  line-height: 2em;
}

.integrations-zapier-wrapper .zap-description {
  font-weight: 500;
}

.integrations-zapier-wrapper .zap-description,
.integrations-zapier-wrapper a.zap-button {
  font-family: 'Quicksand', 'Roboto', 'Helvetica', 'Arial', 'sans-serif';
}

/******************* Team Settings Page **********************/

.teamSettings {
  margin: 24px auto 86px;
  padding: 0 0 24px 0;
}

.teamSettings-group {
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 2fr;
  padding: 24px 10%;
  border-bottom: 1px solid rgba(151, 163, 255, 0.2);
}

@media (max-width: 730px) {
  .teamSettings-group {
    padding: 24px 4%;
  }
}

.retroSettings-columns .teamSettings-group {
  display: block;
}

.teamSettings label {
  font-size: 18px;
  margin-bottom: 18px;
}
.teamSettings-name .MuiTextField-root {
  width: 100%;
  margin-bottom: 24px;
}

.orgSettings label {
  font-size: 18px;
  line-height: 42px;
}

.orgSettings-name .MuiTextField-root {
  width: 80%;
  margin-right: 18px;
}

.teamSettings p {
  text-align: center;
}

.teamSettings-group img {
  width: 220px;
  margin: 8px 0 0 -28px;
}

.teamSettings-group .MuiRadio-root {
  align-self: start;
  margin-left: -8px;
}

.teamSettings-group h3 {
  font-size: 16px;
  margin: 9px 0 0;
  display: inline-block;
}
.teamSettings-group p {
  text-align: left;
  margin: 0;
}

.teamSettings-group:first-child {
  padding-top: 42px;
}

.featuresStepper {
  text-align: center;
  min-height: 150px;
}

.featuresStepper-feature {
  min-height: 62px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.featuresStepper img {
  width: 90%;
}

.teamDashboard-grid .MuiMobileStepper-root {
  background: transparent;
  justify-content: center;
  padding: 0;
}

.teamSettings-plan {
  background: radial-gradient(
      127.95% 136.01% at 50.04% -25.69%,
      rgba(255, 255, 255, 0.25) 0%,
      rgba(255, 255, 255, 0.9) 100%
    ),
    rgba(151, 163, 255, 0.3);
}

.teamSettings-plan p {
  margin-bottom: 12px;
}
.teamZone {
  display: flex;
  justify-content: space-between;
  padding: 24px;
  align-items: center;
  margin-bottom: 86px;
}

.teamZone h4 {
  margin-bottom: -4px;
}

/******************* Invite Page **********************/
.invitePage {
  background: no-repeat bottom center url(images/img/glow.png),
    no-repeat 100% 100%
      linear-gradient(283.22deg, #fcf1f3 8.49%, #f0faf8 26.95%, #eaf7fc 46.69%, #eef8fd 68.57%, #e3f3fa 79.7%);
}
.staticPage-container {
  display: flex;
}
.staticPage-content {
  padding: 20px 40px 40px;
  background: #fdfdfd;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  margin: auto;
}
.staticPage-content h3 {
  font-size: 1.5em;
  font-weight: 300;
}
.staticPage-content p {
  font-weight: 400;
}

/****************** Invite by email ********************/
.invite-by-email--select {
  width: 360px;
  white-space: normal;
}
.invite-by-email--select h4 {
  margin: 0 0 12px 0;
}
.invite-by-email--select p {
  margin: 0;
}
.invite-by-email th {
  text-align: center;
  font-weight: bold;
}
.invite-by-email input {
  background: transparent;
  border-color: rgba(0, 0, 0, 0.23);
  margin: 0;
  padding: 11px 10px;
  max-width: none;
}

.invite-by-email input,
.invite-by-email .MuiFormControl-root {
  width: 100%;
}

.invite-by-email .MuiFormControl-root {
  min-height: 65px;
  margin: 0 0 24px 0;
  overflow: hidden;
}
.invite-by-email .MuiTableCell-root {
  border: none 0;
  padding: 4px 8px;
}
.invite-by-email .MuiTableCell-root:first-child {
  width: 60%;
}
.invite-by-email .MuiTableCell-root:last-child {
  width: 40%;
}
.invite-by-email input:hover {
  border-color: rgba(0, 0, 0, 0.9);
}
.invite-by-email .MuiSelect-outlined {
  padding: 12px 28px 12px 12px;
}

/****************** Modal ********************/
.modalContainer {
  text-align: center;
}
.modalContainer h2 {
  font-size: 30px;
  font-weight: 300;
}

.modalContainer-header {
  margin: 2rem 0;
  font-weight: 700;
}

.modalContainer-form {
  text-align: left;
}

.modalContainer-form .MuiRadio-root {
  align-self: start;
}

.modalContainer-form h3 {
  font-size: 17px;
  margin: 9px 0;
}

.modalContainer-form p {
  margin: 4px 0;
}

.modalContainer-actions {
  margin: 2rem 0 0 0;
}
@media (min-width: 600px) {
  .modalContainer-form {
    margin: 0 24px;
  }
}
/****************** Mui ********************/
body,
textarea,
.react-stickies-wrapper .note-wrap,
#root .MuiButton-root,
.MuiDialog-container .MuiButton-root,
#root .MuiTab-root,
#root .MuiInputBase-input,
.MuiDialog-root .MuiInputBase-input,
.retroFormats .MuiToggleButton-root,
.field .MuiToggleButton-root {
  font-family: 'Quicksand', 'Roboto', 'Helvetica', 'Arial', 'sans-serif';
}

#root .MuiButton-root,
.MuiDialog-container .MuiButton-root {
  font-weight: bold;
  text-transform: none;
  font-size: 1rem;
  padding: 10px 22px;
}

.MuiDialog-container .MuiButton-root {
  margin-top: 2px;
}
#root .MuiInputBase-input,
.MuiDialog-root .MuiInputBase-input,
#root .MuiTableCell-root,
#root .MuiListItem-root {
  font-weight: 500;
}

.MuiFormLabel-root,
.MuiFormHelperText-root,
.MuiTypography-body1 {
  font-family: 'Quicksand', 'Roboto', 'Helvetica', 'Arial', 'sans-serif' !important;
  font-weight: 600 !important;
}

.MuiFormLabel-root,
.MuiFormLabel-root.Mui-focused {
  color: rgba(0, 0, 0, 0.54) !important;
  font-size: 1.1rem !important;
}

.MuiInput-underline:after {
  border-bottom: 2px solid rgba(0, 0, 0, 0.54) !important;
}

.MuiTableCell-body .MuiIconButton-root {
  padding: 4px;
}
#root .MuiSelect-outlined {
  padding: 12px 28px 12px 12px;
}

#root .teamDashboard-grid .MuiSelect-outlined {
  padding: 10px 30px 10px 12px;
}

#root .MuiAlert-root {
  border-radius: 10px;
}

/*----- Pricing -----*/
.plans {
  margin: 24px auto 48px;
  padding: 24px 0 0 0;
}

#pricing {
  padding-top: 4rem;
}
#pricing .content-block-header {
  color: #5365f0;
}
#pricing .content-block-headline::after {
  background: #5365f0;
}
#pricing .section-content-wrapper {
  display: block;
}

#pricing {
  position: relative;
}
#pricing-gradient {
  height: 300px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  opacity: 0.8;
  background: radial-gradient(92.96% 84.67% at 50% -11.08%, rgba(253, 253, 253, 0) 0%, #fdfdfd 81.99%),
    radial-gradient(46.59% 44.34% at 50% -24.06%, #9da4ff 0%, #ccafea 11.98%, #ffbbd3 100%);
}

/*----- Pricing Table -----*/
#pricing-data table {
  position: relative;
  margin-top: 1rem;
  table-layout: fixed;
  border-collapse: collapse;
}

#pricing-data th,
#pricing-data td {
  text-align: center;
  height: 3.125rem; /* 50px */
  border-bottom: 1px solid rgba(151, 163, 255, 0.2);
}
#pricing-data thead td,
#pricing-data thead th {
  border-bottom: none;
  padding-bottom: 0.625rem; /* 10px */
}
#pricing-data thead th {
  padding: 0 10px 10px;
  min-width: 184px;
}

#pricing-data tbody tr:last-child th,
#pricing-data tbody tr:last-child td {
  border-bottom: none;
  padding-bottom: 4rem;
}

.pricing-column {
  width: 25%;
}

#pricing-data thead th {
  font-size: 1.875rem; /* 30px */
}

#pricing-data tbody td,
#pricing-data .pricing-summary td {
  font-size: 1.125rem; /* 18px */
  font-weight: 500;
}

#pricing-data .pricing-summary td {
  padding-bottom: 2rem;
}

#pricing-data tbody th {
  text-align: left;
  font-size: 1.125rem; /* 18px */
  font-weight: 400;
  padding-left: 5rem; /* 96px */
}
#pricing-data .MuiToggleButton-root {
  height: 44px;
}
#pricing-data .MuiToggleButtonGroup-root {
  margin-left: -14px;
  padding-bottom: 22px;
}

#pricing-data .MuiToggleButtonGroup-grouped {
  border: none;
  border-radius: 10px;
  /* color: rgba(242, 108, 124, 1);
  background: rgba(242, 108, 124, 0.1); */

  background: rgba(32, 158, 255, 0.1);
  color: var(--blue);

  text-transform: none;
  font-size: 17px;
  font-weight: 600;

  padding: 8px 15px;
  transition: all 0.2s ease;
}
#pricing-data .Mui-selected.MuiToggleButtonGroup-grouped {
  transition: none;
  /* color: white;
  background: rgba(242, 108, 124, 1); */

  background: var(--blue);
  color: #fff;
  transform: scale(1.1);
}
#pricing-data .MuiToggleButtonGroup-grouped:first-child {
  margin-right: 12px;
  background: rgba(32, 158, 255, 0.1);
  color: var(--blue);
}
#pricing-data .Mui-selected.MuiToggleButtonGroup-grouped:first-child {
  background: var(--blue);
  color: #fff;
}

#pricing-data .priceDiscount {
  position: absolute;
  top: -20px;
  right: -40px;
  border-radius: 14px;
  font-size: 14px;
  color: white;
  background: #161616;
  /* background: rgba(242, 108, 124, 1); */
  padding: 2px 10px;
}

#pricing-data .Mui-selected .priceDiscount {
  background: #161616;
}

.pricing-price {
  display: flex;
  padding-bottom: 22px;
  align-items: center;
  justify-content: center;
}

.pricing-price-row {
  height: 92px;
}

.pricing-currency-symbol {
  position: relative;
  font-size: 1.125rem; /* 18px */
  font-weight: 400;
}

.pricing-currency-amount {
  align-self: center;
  justify-self: center;
  font-size: 2.1875em; /* 35px */
  font-weight: 500;
  padding: 0 6px 0 2px;
}

.pricing-period {
  font-size: 1.0625rem; /* 17px */
  font-weight: 400;
  position: relative;
  left: -0.2rem;
}
.pricing-period span {
  display: flex;
}

.pricing-annual {
  margin: -22px 0 22px 0;
  display: block;
  font-size: 16px;
  font-weight: 400;
}

.included {
  background: url('images/icons/checkmark.svg') center no-repeat;
}

.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.pricing-summary-title {
  font-size: 1.125rem; /* 18px */
  font-weight: bold;
  margin-bottom: 0;
  line-height: 175%;
}

.pricing-summary-text {
  font-size: 1.125rem; /* 18px */
  margin: 0 auto 1rem;
  line-height: 175%;
  font-weight: 400;
  max-width: 13rem;
}

.pricing-summary .text-button,
.text-button-secondary {
  border: 1px solid var(--blue);
  padding: 10px 15px;
  border-radius: 10px;
  font-size: 1rem;
  transition: all 0.3s cubic-bezier(0.73, 1, 0.6, 1.8);
  display: inline-block;
  margin: 0.3125rem 0;
  font-weight: 500;
}

.pricing-summary .text-button:hover,
.text-button-secondary:hover {
  text-decoration: none;
  transform: scale(1.03);
  filter: brightness(103%);
}

@media (max-width: 906px) {
  #pricing-data {
    overflow: auto;
  }

  #pricing-data tbody th {
    padding-left: 3rem;
  }
}

/**responsive**/
@media (max-width: 700px) {
  .pageMain {
    padding: 36px 24px 24px;
  }
}

@media (max-width: 600px) {
  .pageMain {
    padding: 36px 12px 24px;
  }
}

/****************animation***************/
@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}

/****************giphy***********************/
.giphy-panel .giphy-carousel {
  pointer-events: auto;
  padding: 0px 10px 10px 10px;
  height: 125px;
  transition: opacity 1.3s cubic-bezier(0.4, 0, 0.2, 1) 0s;
}
.giphy-panel .giphy-gif {
  overflow: visible;
  cursor: pointer;
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0s;
}

.giphy-panel .giphy-gif:hover {
  transform: translate(0, -6px);
}

.giphy-panel .giphy-gif-img {
  border-radius: 5px;
}

.giphy-panel .giphy-carousel--selected {
  display: none;
}

.giphy-panel .MuiRadio-root {
  color: rgba(255, 255, 255, 0.54);
}
.giphy-panel .MuiFormControlLabel-label {
  font-size: 14px;
}
.giphy-panel .MuiFormGroup-root {
  justify-content: space-around;
}
.giphy-view .giphy-gif {
  position: fixed;
  left: 100vw;
  bottom: 8px;
  animation: flyaway 20s linear;
}

/* css for overlay */
.giphy-panel .giphy-overlay {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  color: white;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.4);
}

@keyframes flyaway {
  0% {
    opacity: 1;
    left: -50px;
  }

  100% {
    left: 100vw;
    opacity: 1;
  }
}
.giphy-panel .giphy-panel--input {
  width: 160px;
}
@media (max-width: 600px) {
  .giphy-panel .giphy-panel--input {
    width: 90px;
  }
  .giphy-panel--logo {
    width: 60px;
  }

  .giphy-panel .MuiFormGroup-root {
    flex-direction: column;
  }

  .giphy-panel .MuiRadio-root {
    padding: 3px 4px 3px 12px;
  }
}

/*******************Stage glitter************************/
.star-shine {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  animation: fadeIn 3s linear;
  animation-iteration-count: 2;
  animation-direction: alternate;
  animation-delay: 5s;
}
.shine-popup {
  position: absolute;
  bottom: 10px;
  left: -8px;
  opacity: 1;
  animation: fadeOut 1s linear;
  animation-iteration-count: 1;
  animation-delay: 5.5s;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
  from {
    opacity: 0;
  }
}

@keyframes fadeOut {
  to {
    opacity: 0;
  }
  from {
    opacity: 1;
  }
}

/*----- Footer -----*/
footer section {
  position: relative;
  width: 100%;
  margin: 0 4vw;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: var(--section-height);
  padding-left: 1.2rem;
  padding-right: 1.2rem;
}

.section-content-wrapper {
  padding-top: 0;
  max-width: none;
  justify-content: center;
  align-items: center;
  z-index: 1;
  width: 100%;
  max-width: var(--max-width);
  grid-gap: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

footer {
  background: var(--color-dark);
  font-size: 1.0625rem; /* 17px */
  color: var(--color-background);
  font-weight: 300;
  min-height: 400px;
  width: 100%;
  display: flex;
}

footer #teleretro-logo {
  left: -7px;
  top: 26px;
  margin-bottom: 7px;
}

footer a {
  color: var(--color-background);
  text-decoration: none;
}

footer a:hover {
  color: var(--color-background);
  text-decoration: underline;
}

footer section {
  min-height: 280px;
}

footer .section-content-wrapper {
  grid-template-columns: 1fr 2fr;
}

footer .section-content-wrapper img {
  object-position: left;
}

footer nav > ul {
  display: grid;
  grid-gap: 24px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

footer ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
}

footer li {
  line-height: 1.7rem;
}

footer ul li ul {
  flex-direction: column;
}

footer li img {
  transition: transform 0.3s cubic-bezier(0.73, 1, 0.6, 1.8);
}

.footer-list-header {
  font-weight: 500;
  margin-bottom: 1rem;
}

.footer-about-teleretro {
  max-width: 300px;
  display: flex;
  flex-direction: column;
}

.social-link:hover img {
  transform: scale(1.2);
}

.copyright {
  color: white;
  opacity: 0.5;
}

/*----- Small screen overrides -----*/

@media (max-width: 700px) {
  footer section {
    padding-top: 2rem !important;
    padding-bottom: 3rem;
  }

  .section-content-wrapper {
    grid-gap: 50px;
    grid-template-columns: 1fr !important;
  }

  footer {
    padding: 2rem 0;
    justify-content: flex-start;
  }

  footer section {
    padding-top: 0 !important;
    padding-bottom: 5rem;
  }

  .copyright {
    position: absolute;
    bottom: 0;
  }

  footer #teleretro-logo {
    display: none;
  }

  footer .footer-about-teleretro {
    width: 100%;
    max-width: none;
  }

  footer nav > ul {
    grid-template-columns: 1fr 1fr;
  }

  footer ul {
    width: 100%;
    display: inline;
  }

  footer ul li {
    width: 100%;
    margin-bottom: 0.5rem;
  }

  .section-content-wrapper .furniture img {
    height: 100%;
  }
}

/*******************Icebreaker*******************/
.searchGroup-results--icebreaker .iceBreaker-field {
  margin: 0 12px;
  border-radius: 25px;
}

.searchGroup-results--icebreaker .MuiOutlinedInput-notchedOutline {
  /* border-color: #e9f5ff;
  border-width: 4px; */
}
.searchGroup-results--icebreaker .iceBreaker-field input {
  padding: 12.5px 14px;
}

/******************* Mood *******************/
.moodSelectorEmoji-element {
  margin: 4px;
  text-align: center;
  font-size: 50px;
  line-height: 50px;
  cursor: pointer;
  transform: scale(1);
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1), border 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  border-radius: 50%;
  border: 12px solid transparent;
}
.moodSelectorEmoji-selected .moodSelectorEmoji-element,
.moodSelectorEmoji-element:hover {
  transform: scale(1.2);
}

.moodSelectorEmoji-selected .moodSelectorEmoji-element {
  background: #d1eafd;
  border: 12px solid #d1eafd;
  transform: scale(1);
}

.changeMoodPanel {
  min-height: 100px;
}

.changeMoodPanel .moodSelectorEmoji-element {
  font-size: 20px;
  line-height: 20px;
}

.moodGraph {
  display: flex;
  flex-direction: column-reverse;
  min-width: 200px;
  margin: 16px 0;
}
.moodGraph-value {
  min-width: 9px;
  text-align: center !important;
}
.moodGraph-mood {
  display: flex !important;
  justify-content: space-between;
  margin: 4px 10px;
}

.moodGraph-emoji {
  text-align: center !important;
  margin: 0 8px;
}
.moodGraph-percentage {
  text-align: right !important;
  margin-left: 8px;
  min-width: 38px;
}
.moodGraph-bar {
  flex-grow: 1;
  display: flex !important;
  justify-content: center;
  flex-direction: column;
}
.moodGraph-bar--container {
  border-radius: 20px;
  background-color: rgba(158, 158, 158, 0.15);
  display: flex;
}

.moodGraph-bar--element {
  display: inline-block;
  height: 12px;
  transition: width 0.8s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  border-radius: 20px;
}

.moodGraph-bar-1 .moodGraph-bar--element {
  background-color: #f26c7c;
}

.moodGraph-bar-2 .moodGraph-bar--element {
  background-color: #ffa033;
}

.moodGraph-bar-3 .moodGraph-bar--element {
  background-color: #ffd233;
}
.moodGraph-bar-4 .moodGraph-bar--element {
  background-color: #cbea71;
}
.moodGraph-bar-5 .moodGraph-bar--element {
  background-color: #6ae19a;
}

@media (max-width: 500px) {
  .moodPollGrid {
    transform: scale(0.7);
  }
}

/******************* Org Pulse *******************/
.dashboard-pulse {
  position: relative;
  width: 100%;
  min-height: 82px;
  margin: 0 0 24px 0;
  text-align: center;
}

.dashboard-pulse--org,
.dashboard-pulse--team {
  height: 500px;
  display: flex;
  align-content: center;
  justify-content: center;
  margin: 0;
}
.dashboard-pulse--content {
  position: relative;
  z-index: 1;
}
.dashboard-pulse--content p {
  margin: 0 24px;
}
.dashboard-pulse h1 {
  padding: 36px 0;
  font-size: 35px;
  font-weight: 400;
}
.dashboard-pulse--team h1 {
  padding: 24px 0;
}
.dashboard-pulse h1 span {
  font-weight: 500;
}

.dashboard-pulse button {
  margin: 1.8% 0 1.8% -16%;
}
.dashboard-pulse--org button {
  margin: 44px auto;
}

.dashboard-pulse--team button {
  margin: 44px auto;
}
.orgPulseInfo {
  position: relative;
  text-align: center;
  margin-top: -14px;
}

@media (max-width: 900px) {
  .dashboard-pulse--content {
    text-align: center;
  }
  .dashboard-pulse button {
    margin: 10% auto 0;
  }

  .orgPulseInfo .info-help-text--dark {
    margin-bottom: 48px;
  }
}
/******************* pulseTemplate *******************/
.pulseTemplate {
  width: 100%;
  background: rgba(255, 255, 255, 0.8);
  text-align: center;
  overflow: auto;
  margin: 0 24px 24px;
}

.pulseTemplate-actions {
  float: right;
}

.pulseTemplate-actions--topic svg {
  margin: -12px 24px 0;
  fill: rgba(0, 0, 0, 0.6);
  cursor: pointer;
}

.pulseSection {
  position: relative;
  align-items: center;
}
.pulseSection-actions {
  position: absolute;
  top: 12px;
  right: 12px;
}

/******************* Pulse Editor *******************/
.pulseDashboard {
  margin-bottom: 24px;
}
.pulseDashboard h2 {
  font-size: 19px;
  font-weight: 500;
  text-align: center;
  padding-top: 24px;
  margin: 0;
}
.PulseEditorWrapper {
  display: flex;
  justify-content: center;
}

.pulsePreview {
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-wrap: anywhere;
  min-height: 80vh;
}
.pulseEditorArea {
  width: 30%;
  background: rgba(255, 255, 255, 0.8);
  overflow: auto;
}
.pulseCard {
  padding: 40px;
}

@media (max-width: 1200px) {
  .pulsePreview {
    width: 60%;
    margin: 0 24px;
  }
  .pulseEditorArea {
    width: 40%;
  }
}

@media (max-width: 960px) {
  .pulsePreview {
    height: 100vh;
  }
}

@media (max-width: 780px) {
  .PulseEditorWrapper {
    flex-direction: column;
  }

  .pulsePreview {
    height: auto;
    width: 100%;
    margin: 24px 0;
    min-height: auto;
  }

  .pulseEditorArea {
    width: 100%;
  }
}

.pulseCard-header {
  text-align: center;
}

.pulseCard-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.pulseCard-topic {
  font-size: 17px;
  font-weight: 500;
  margin: 0 22px 0 0;
  padding: 4px 24px 4px 0;
}

.pulseArea-editable {
  border: 1px solid transparent;
  background: none center right no-repeat;
  padding-right: 24px;
}

.pulseArea-editable:hover {
  background-image: url('images/icons/edit.svg');
}

.pulseArea-editable--editing {
  outline: 0;
  border: 1px solid var(--blue);
  background-image: none !important;
}

.pulseArea-editable[contentEditable='true']:empty:before,
.poll-placeholder:empty:before {
  content: attr(placeholder);
  opacity: 0.3;
}
h2 .poll-placeholder {
  margin-left: 8px;
}

.pulseArea-comments {
  margin: 24px 8px;
  padding: 8px 16px;
  background: #fafafa;
  border-radius: 10px;
}

.pulseEditor-actions {
  cursor: pointer;
}

.pulseCard-wrapper:hover .pulseEditor-actions svg {
  fill: grey;
}

.pulseCard h3 {
  margin: 0;
}
.pulseCard-section {
  padding: 0 0 24px 0;
}
.pulseCard-section label {
  font-size: 14px;
  font-weight: 600;
  padding: 0 0 4px 0;
  color: var(--color-dark);
}
.pulseCard-section .text-button {
  font-weight: 500;
}
.pulseCard-section .reorderIcon {
  display: flex;
  fill: var(--color-dark);
  opacity: 0.6;
}

.pulseModel--dimensions--scale {
  display: flex;
  justify-content: center;
}

.pulseModel--dimensions--label {
  flex: 1;
}

.pulseEditor--emojiPicker {
  cursor: pointer;
  margin-right: 4px;
  display: flex;
  align-items: center;
  width: 20px;
  justify-content: center;
}

h2 .pulseEditor--emojiPicker svg {
  width: 32px;
  height: 32px;
}
.pulseEditor-modal .MuiDialogContent-root {
  padding: 0 !important;
}

/****************** Survey View ********************/

.surveyContainer {
  width: 680px;
  text-align: center;
}
@media (max-width: 680px) {
  .surveyContainer {
    width: auto;
    margin: 0 12px;
    overflow: auto;
  }
}
.surveyView {
  text-align: center;
  padding: 30px 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.surveyView h2 {
  display: flex;
  justify-content: center;
  font-size: 32px;
  font-weight: 300;
  margin: 0 0 1rem 0;
}

.surveyView p {
  font-size: 17px;
  margin: 0 0 24px;
  font-weight: 400;
  line-height: 1.5em;
}

.surveyView .MuiMobileStepper-root {
  background: transparent;
}

.pulseSummary {
  position: relative;
  overflow: hidden;
  padding: 40px;
  min-height: calc(100vh - 48px - 48px - 48px - 48px - 40px - 40px);
}

.pulseSummary .surveyValue {
  margin-right: 0;
}

/****************** PulseTable ********************/
.pulseTable {
  display: flex;
  padding: 12px 0;
}
.pulseTable--org {
  padding-top: 100px;
}
.pulseTable table {
  margin: 0 auto;
  width: 100%;
}
.pulseTable th {
  text-align: left;
  position: sticky;
  left: 0;
  background: #fff;
  z-index: 1;
}

.pulseTable-header {
  font-size: 14px;
  font-weight: 500;
  width: 42px;
  padding: 0 0 12px 0;
}

.pulseTable--org .pulseTable-header {
  position: relative;
  rotate: -45deg;
}
.pulseTable--org .pulseTable-header span.text-button {
  white-space: nowrap;
  position: absolute;
  top: -12px;
  left: 15px;
}
.pulseTable--team tr:first-child .pulseTable-header {
  padding: 12px 0 0 0;
}
.pulseTable-tooltip {
  padding: 0 12px 12px;
  width: 312px;
  max-height: 300px;
  overflow: auto;
}
.surveyTopic {
  width: 200px;
  text-align: left;
  font-size: 18px;
  font-weight: 500;
}
.surveyTopic--icon {
  display: inline-block;
  min-width: 20px;
}
.surveyDate {
  font-size: 12px;
  font-weight: 500;
  width: 36px;
  height: 36px;
  background: #e9f5ff;
  /*box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.09);*/
  border-radius: 11px;
  margin: 7px auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.surveyDate span:first-child {
  font-size: 11px;
}
.surveyValueWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.surveyValue {
  width: 36px;
  height: 36px;
  line-height: 36px;
  background: #faf8f5;
  /*box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.09);*/
  border-radius: 11px;
  margin: 2px auto;
  text-align: center;
  display: inline-block;
}

.surveyValue--icon {
  background: transparent;
}

.surveyValue--noValue {
  /*background: #DCDCDC;*/
}
.surveyValue--noValue::after {
  content: '-';
}
.pulseResults {
  overflow: auto;
  padding: 12px 24px;
  max-height: calc(100vh - 300px);
}
.pulseComments {
  color: #fff;
  padding: 8px 24px 0 24px;
  border-radius: 20px;
  overflow: auto;
  max-height: calc(100vh - 284px);
}
.retro-summary .pulseComments {
  color: var(--color-dark);
  background: #f4f2f2;
}
.surveyComment {
  border-bottom: 1px solid #e2e1e4;
  padding: 0 0 20px;
}

.surveyComment .surveyValue {
  width: 25px;
  height: 25px;
  line-height: 25px;
  font-size: 14px;
  margin: 0 8px;
}

.surveyComment p {
  margin: 24px 0 12px;
}

.surveyComment p::before {
  content: '“';
}
.surveyComment p::after {
  content: '”';
}

.surveyPercent {
  display: flex;
  justify-content: space-around;
  width: 28px;
  margin: 7px auto;
  height: 28px;
  align-items: flex-end;
}
.surveyPercent span {
  width: 3px;
  min-height: 3px;
  border-radius: 3px;
}

.teamPulseContainer .pulseTable-header--team {
  rotate: 0deg;
}
.teamPulseContainer .surveyDate,
.teamPulseContainer .surveyValue {
  width: 172px;
  border-radius: 0;
  border-right: 2px solid #fff;
}
.teamPulseContainer th + td .surveyDate,
.teamPulseContainer th + td .surveyValue {
  border-radius: 11px 0 0 11px;
}
.teamPulseContainer tr td:last-child .surveyDate {
  border-radius: 0 11px 11px 0;
}
.teamPulseContainer tr td:last-child .surveyValue,
.teamPulseContainer tr td.surveyValueLastContent .surveyValue {
  border-top-right-radius: 11px;
  border-bottom-right-radius: 11px;
}

.pulseTable thead tr:last-child {
  border-bottom: 1px solid #ecedf0;
}
.pulseTable thead tr:last-child td {
  padding-bottom: 6px;
}

.pulseTable tbody tr:first-child td {
  padding-top: 6px;
}

.surveyPercent + .surveyValue {
  margin-left: 12px;
}
.info-help-text {
  text-align: center;
  background: rgba(18, 36, 62, 0.3);
  border-radius: 10px;
  color: #fff;
  padding: 8px 14px;
  display: inline-block;
}
.info-help-text--dark {
  display: inline-flex;
  background: #12243e;
}
.surveyContainer .info-help-text {
  padding: 8px 14px 12px;
  margin: 0 76px 24px;
}
/* .teamPulseContainer th + td .surveyDate,
.teamPulseContainer th + td .surveyValue {
  border-top-left-radius: 11px;
  border-bottom-left-radius: 11px;
}
.teamPulseContainer tr td:last-child .surveyDate
.teamPulseContainer tr td:last-child .surveyValue {
  border-top-right-radius: 11px;
  border-bottom-right-radius: 11px;
} */

.integration-added-icon path {
  fill: rgb(51, 224, 51);
}

.asigneeSearchBox {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  background: #fff;
  box-shadow: 0px 5px 22px rgba(0, 0, 0, 0.07);
}
.asigneeSearchBox input {
  padding: 12px 0 12px 16px !important;
}

.asigneeSearchBox .MuiAutocomplete-popupIndicator {
  margin-right: 6px;
  width: 24px;
  height: 24px;
  background: no-repeat url('images/icons/close-dark.svg') center center;
}

.asigneeSearchBox .MuiAutocomplete-popupIndicator svg {
  display: none;
}
.removeAssigneeBtn {
  margin: auto 4px;
  fill: #a7a7a7 !important;
}
.removeAssigneeBtn:hover {
  fill: var(--blue) !important;
}
