.react-stickies-wrapper .clearfix:before,
.react-stickies-wrapper .clearfix:after {
  content: ' ';
  display: table;
}

.react-stickies-wrapper .clearfix:after {
  clear: both;
}

.react-stickies-wrapper .clearfix {
  *zoom: 1;
}
/*
.react-stickies-wrapper {
  padding-top: 40px;
}*/
.actionPanelBoard .react-stickies-wrapper {
  padding-top: 16px;
}
.react-grid-layout {
  min-height: calc(100vh - 48px - 55px - 66px); /*main menu,  borad header, add buttons*/
}

.main-admin .react-grid-layout {
  min-height: calc(100vh - 48px - 55px - 66px - 48px); /* extra admin space*/
}

.react-stickies-wrapper-action .react-grid-layout {
  min-height: auto;
  transition: none;
}

.boardContainer {
  text-align: center;
  transform-origin: 50% 0;
}

/* Survey */
.surveyWrapper {
  margin: 0 auto;
  height: calc(100vh - 48px);
  display: flex;
  justify-content: center;
  align-items: center;
      
}
.main-admin  .surveyWrapper {
  height: calc(100vh - 48px - 48px);
}

@media (max-width: 740px) {
  .surveyWrapper {
    display: block;
  }
}

/* Note styles */

.noteContainer {
  user-select: none;
}

.note-wrap {
  width: 100%;
  height: 100%;
  box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.09);
  border: 2px solid transparent;
  border-radius: 10px;
  box-sizing: border-box;

  background: #fff;
  user-select: none;

  transition: border-color 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0s;
}

.note-blur {
  filter: blur(5px);
}

.note-editing,
.note-grouping {
  border-color: var(--pink);
  box-shadow: none;
}

.actionPanelList .note-wrap {
  color: #fff;
  box-shadow: none;
  border-radius: 0;
  background: #eee;
  user-select: none;
}

.react-stickies-wrapper .removeVote,
.react-stickies-wrapper .voteCount,
.react-stickies-wrapper .commentsCount {
  position: absolute;
  color: #fff;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0s, background 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  top: -4px;
  background: transparent;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
}

.react-stickies-wrapper .commentsCount {
  display: flex;
  align-items: center;
  width: auto;
  height: auto;
  top: 0;
  right: 6px;
  color: var(--color-dark);
  background:  transparent;
  cursor: pointer;
  opacity: 1;
}
.react-stickies-wrapper .commentsCount svg {
  width: 16px;
}
.react-stickies-wrapper .commentsCount span {
  font-size: 13px;
  padding: 0 0 2px 1px;
}
.react-stickies-wrapper .commentsCount:hover svg path:first-child {
  fill: var(--blue);
  fill-opacity: 0.1;
}
.react-stickies-wrapper .commentsCount:hover svg path:last-child {
  fill: var(--blue);
  fill-opacity: 1;
}

.close svg {
  fill: #a7a7a7;
}

.close:hover svg {
  cursor: pointer;
  fill: #f26c7c;
}

.noteOptions {
  opacity: 0;
  transition: opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0s;
}

.react-grid-item:hover .noteOptions {
  opacity: 1;
}

.noteContainer .noteOptions {
  display: flex;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 20;
}
.noteAction {
  height: 20px;
  line-height: 24px;
  border-radius: 10px;
  padding: 0 8px 8px 0;
  transition: opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0s;
}

.noteAction svg {
  fill: grey;
}

.MuiPaper-root .action-delete {
  color: rgba(242, 108, 124, 1);
}

.MuiPaper-root .action-link,
.MuiPaper-root .action-link:hover,
.MuiPaper-root .action-link:visited {
  color: #000;
  text-decoration: none;
}

.MuiPaper-root .MuiMenuItem-root {
  font-weight: 500;
}

.vote .noteOptions {
  display: none;
}

.vote .emojiCounter {
  pointer-events: none;
}
.insertEmoji {
  opacity: 0;
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0px 0px 0px 5px;
  cursor: pointer;
}

.insertEmoji svg {
  padding: 2px 5px;
}

.note-editing + .emojiCounter + .noteOptions {
  display: none;
}

.note-editing + .emojiCounter + .noteOptions + .insertEmoji {
  opacity: 1;
}

.react-stickies-wrapper .removeVote {
  background: var(--red);
  left: 19px;
}

.react-stickies-wrapper .voteCount {
  left: -8px;
  background: #209eff;
  cursor: auto;
  opacity: 1;
}

.react-stickies-wrapper .addVote {
  position: absolute;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
}

.vote .react-grid-item .addVote {
  display: block;
}

.addVote:focus {
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.vote .react-grid-item:hover .note-wrap {
  background: var(--light-blue);
}

.react-stickies-wrapper .removeVote svg {
  width: 100%;
  height: 100%;
}

.react-stickies-wrapper .note-body {
  padding: 12px 10px; /* padding top + bottom should be row height */
  font-size: 12px;
  color: #747474;
  height: calc(100% - 24px);
  user-select: none;
}

.hideClose {
  display: none;
}

/* hover can cause click issues on touch device  */
.vote .react-grid-item:hover .removeVote,
.vote .voteCount {
  opacity: 1;
}

.react-stickies-wrapper [contenteditable]:focus {
  outline: 0px solid transparent;
}

.react-grid-placeholder {
  border: 2px dashed #fff;
}

.note textarea {
  height: 100%;
  user-select: auto;
  text-align: center;
  overflow-x: hidden;
  overflow-y: auto;
  font-size: 17px;
  line-height: 24px;
  cursor: default;
}

#root .addActionBtn {
  margin: 12px 0;
}

.react-grid-item .MuiAvatarGroup-avatar {
  width: 24px;
  height: 24px;
  border: none;
  font-size: 12px;
  font-weight: 500;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
}

.typing {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.magic-cursor {
  width: 20px;
  height: 20px;
  background-color: var(--blue);
  border-radius: 50%;
  position: absolute;
  top: -100px;
  pointer-events: none;
  opacity: 0;
  transition: width 0.3s, height 0.1s, left 0.3s, top 0.3s, opacity 0.3s;
  box-shadow: 0px 0px 5px 0px #747474;
}

.magic-cursor-visible {
  opacity: 1 !important;
}
