.emojiCounter {
  display: flex;
  position: absolute;
  bottom: 12px;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.emojiCounter-group {
  cursor: pointer;
}
.emojiCounter-group:hover {
  background: rgba(220, 220, 220, 0.6);
}
